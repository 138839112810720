import fetch from "isomorphic-fetch";
import { v4 as uuidv4 } from 'uuid';

export const BASE_API_URL = getBaseApiUrl();

export function getBaseApiUrl() {
  let BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  return BASE_API_URL;
}

export async function api(end_point, method, data) {
  let request = {
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
      "auth-token": getCookie("radminauth"),
      "x-correlation-id": uuidv4()
    },
    method: method
  };
  
  if (!["GET", "DELETE"].includes(method)) {
        request.body = JSON.stringify(data);
}

  const response = await fetch(BASE_API_URL + end_point, request);
  if (response) {
    if (response.status === 401) {
    } else if (response.status > 401) {
        return response.json();
    }
    return await response.json();
  }
    
}

export async function apiFormData(end_point, method, data) {
  let request = {
    headers: {
      "auth-token": getCookie("radminauth"),
      "x-correlation-id": uuidv4()
    },
    method: method
  };
  if (!["GET", "DELETE"].includes(method)) {

    if (data['file']) {
      let formData = new FormData();
      formData.append('file', data['file'])
      data['fieldData'] && formData.append('fieldData', JSON.stringify(data['fieldData']))
     
      request.body = formData
    } else {
        request.body = JSON.stringify(data);
    }
  }

  const response = await fetch(BASE_API_URL + end_point, request);
  if (response) {
    if (response.status === 401) {
    } else if (response.status > 401) {
        return response.json();
    }
    return await response.json();
  }
    
}

export async function apiMultipleFormData(end_point, method, data) {
  let request = {
    headers: {
      "auth-token": getCookie("radminauth"),
      "x-correlation-id": uuidv4()
    },
    method: method
  };
  if (!["GET", "DELETE"].includes(method)) {
    let formData = new FormData();
    let files = data['files']

    if (data['files']) {
      for (const file of files) {
        formData.append('file', file);
      }
      data['fieldData'] && formData.append('fieldData', JSON.stringify(data['fieldData']))
      request.body = formData
    } else {
        request.body = JSON.stringify(data);
    }
  }

  const response = await fetch(BASE_API_URL + end_point, request);
  if (response) {
    if (response.status === 401) {
    } else if (response.status > 401) {
        return response.json();
    }
    return await response.json();
  }
    
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  let cookies = [];
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      cookies.push(c.substring(name.length, c.length));
    }
  }
  if (cookies.length === 0) {
    return "";
  } else {
    return cookies[cookies.length - 1];
  }
}

