
import "core-js/es/array";
import React, { useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./index.css";
import defaultState from "./store/DefaultState";
import { Reducer, Context } from "./reducers/MainReducer";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./components/Pages/Main"));

// Pages
const Login = React.lazy(() => import("./components/Pages/Login"));

function App() {
  const [store, dispatch] = useReducer(Reducer, defaultState);

  return (
    <Context.Provider value={{ store, dispatch }}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
          <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
