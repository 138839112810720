import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import configureStore from "./store/ConfigureStore";
import * as actionCreators from "./actions/ActionCreators";

React.icons = icons;

const store = configureStore();

window.store = store;

store.dispatch(actionCreators.validateCookie());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
