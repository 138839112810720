import { api, getCookie, apiFormData, apiMultipleFormData } from "./Api";

export const APP_ROUTES = {
  LOGIN: "login",
  SIGNUP: "signup",
  DASHBOARD: "dashboard",
}

function redirectToLogin() {
  const route = window.location.pathname.replace("/", "").trim();
  if (route !== APP_ROUTES.LOGIN) {
    window.location.replace(`/${APP_ROUTES.LOGIN}`);
  }
}

export function validateCookie() {
  return function (dispatch) {
    if (getCookie("radminauth")) {
      return api("auth/validate-cookie", "GET")
        .then((json) => {
          if (json["success"] === true) {
            let route = window.location.pathname.replace("/", "").trim();
            if (route === "") {
              route = "/dashboard";

            }
          } else redirectToLogin()
          dispatch(loginSucceed(json["data"].user));
        })
        .catch((err) => redirectToLogin()
        );
    } else redirectToLogin()
    }
  };

function loggingIn() {
  return {
    type: "LOGGING_IN",
  };
}

function loginFailed() {
  return {
    type: "LOGIN_FAILED",
  };
}

function loginSucceed(user) {
  return {
    type: "LOGIN_SUCCEEDED",
    user
  };
}

export function loading() {
  return {
    type: "LOADING",
  };
}

export function login(data) {
  return function (dispatch) {
    dispatch(loggingIn());
    return api("login", "POST", data).then((json) => {
      if (!json["success"]) {
        dispatch(loginFailed());
        return;
      }
      let auth_key = json["data"].auth_token;
      let expires;
      var d = new Date();
      expires = d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      document.cookie=`radminauth=${auth_key};expires=${expires};path=/`
      dispatch(loginSucceed(json["data"].user));
    });
  };
}

export function logoutLocal() {
  return {
    type: "LOGOUT_LOCAL",
  };
}

function resetCookies() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}

export function logout() {
  return function (dispatch) {
    resetCookies();
    window.location.replace(`/login`);
    dispatch(logoutLocal());
  };
}

function requestFetchEntity(entity) {
    return {
      type: "REQUEST_FETCH_ENTITY",
      entity
    };
  }

export function fetchCommonSinglePage(entity, page_number) {
        return function (dispatch) {
            dispatch(requestFetchEntity(entity.toLowerCase()));
            return api(`get/${entity}/${page_number? page_number : '1'}`, "GET").then((json) => {
              if (json.status === 401) return redirectToLogin();
              if (json.data) {
                const type = `FETCH_${entity.replace(/-/g, '_').toUpperCase()}`;
                let pageCount = json.data.page_count ? json.data.page_count : (json.data.page_count === 0 ? 1 : "")
                dispatch(responseFetchCommon(type, json.data.entities, pageCount));
              }
            });
          };
  }

  export function responseFetchCommon(type, data, page_count) {
    return {
      type: type,
      payload: data,
      total_pages: page_count
    };
  }

  export function fetchCommon(entity) {
    return function (dispatch) {
      dispatch(requestFetchEntity(entity.toLowerCase()));
      return api(`get/${entity}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]){
        const type = `FETCH_${entity.replace(/-/g, '_').toUpperCase()}`;
        dispatch(responseFetchCommon(type, json.data.entities));
        };
      });
    }
  }

  function responseUpdateEntityStatus(entity, response) {
    return {
      type: "RESPONSE_UPDATE_STATUS",
      response,
      entity
    };
  }
  
  export function updateEntityStatus(entity, id, data) {
        return function (dispatch) {
            return api(`update/${entity}/status/${id}`, "PUT", data).then((json) => {
                dispatch(responseUpdateEntityStatus(entity, json));
                return json;
              }
            );
          };
  }

  export function fetchFilteredTableData(entity, data, page_number) {
    return function (dispatch) {
      dispatch(requestFetchEntity(entity.toLowerCase()));
      return api(`get/filter/${entity}/${page_number}`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        const type = `FETCH_${entity.replace(/-/g, '_').toUpperCase()}`;
        let pageCount = json.data.page_count ? json.data.page_count : (json.data.page_count === 0 ? 1 : "")
        dispatch(responseFetchCommon(type, json.data.entities, pageCount));
        return json;
      });
    };
  }

  function requestFetchCampaignById() {
    return {
      type: "REQUEST_FETCH_CAMPAIGN_BY_ID",
      
    };
  }
  
  function responseFetchCampaignById(id, response) {
    return {
      type: "RESPONSE_FETCH_CAMPAIGN_BY_ID",
      id,
      response
    }
  }
  
  export function fetchCampaignById(id) {
    return function (dispatch) {
      dispatch(requestFetchCampaignById());
      return api(`get/campaign/data/${id}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]) {
        dispatch(responseFetchCampaignById(id, json.data));
        };
      });
    }
  }

  function requestFetchRoById() {
    return {
      type: "REQUEST_FETCH_RO_BY_ID",
      
    };
  }

  export function fetchDemoCampaignById(id) {
    return function (dispatch) {
      dispatch(requestFetchCampaignById());
      return api(`get/demo_campaign/data/${id}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]) {
        dispatch(responseFetchCampaignById(id, json.data));
        };
      });
    }
  }
  
  function responseFetchRoById(id, response) {
    return {
      type: "RESPONSE_FETCH_RO_BY_ID",
      id,
      response
    }
  }

  export function fetchRoById(id) {
    return function (dispatch) {
      dispatch(requestFetchRoById());
      return api(`get/ro/data/${id}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]) {
        dispatch(responseFetchRoById(id, json.data));
        };
      });
    }
  }

  function requestFetchSingleEntity(entity) {
    return {
      type: "REQUEST_FETCH_SINGLE_ENTITY",
      entity
    };
  }
  
  function responseFetchSingleEntity(entity, data, id) {
    return {
      type: "RESPONSE_FETCH_SINGLE_ENTITY",
      entity,
      data,
      id
    }
  }
  
  export function fetchSingleEntity(entity, id, type) {
    return function (dispatch) {
      dispatch(requestFetchSingleEntity(entity.toLowerCase()));
      return api(`get/single/${entity}/${id}?type=${type}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]) {
        dispatch(responseFetchSingleEntity(entity, json.data, id));
        };
      });
    }
  }

  function requestUpdateCampaign() {
    return {
      type: "REQUEST_UPDATE_CAMPAIGN",
    };
  }
  
  function responseUpdateCampaign(response, id, data, entity) {
    return {
      type: "RESPONSE_UPDATE_CAMPAIGN",
      response,
      id,
      data,
      entity
    };
  }
  
  export function updateCampaign(id, data) {
    return function (dispatch) {
      dispatch(requestUpdateCampaign());
      return api(`update/campaign-general-details/${id}`, "PUT", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
          dispatch(responseUpdateCampaign(json, id, data));
          return json;
        }
      );
    };
  } 

  export function updateCampaignIrctcTargeting(id, data) {
    return function (dispatch) {
      dispatch(requestUpdateCampaign());
      return api(`update/campaign-irctc-target/${id}`, "PUT", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
          dispatch(responseUpdateCampaign(json, id, data, "campaign_irctc_targeting"));
          return json;
        }
      );
    };
  } 

  function requestUpdateEntity(entity) {
    return {
      type: "REQUEST_UPDATE_ENTITY",
      entity
    };
  }
  
  function responseDeleteEntity(response, entity, id, campId) {
    return {
      type: "RESPONSE_DELETE_ENTITY",
      response,
      entity,
      id,
      campId
    };
  }
  
  export function deleteEntity(entity, id, campId) {
    entity = entity.toLowerCase()
    return function (dispatch) {
      dispatch(requestUpdateEntity(entity));
      return api(`delete/${entity}/${id}`, "DELETE").then(
        (json) => {
          if (json.status === 401) return redirectToLogin();
          dispatch(responseDeleteEntity(json, entity, id, campId));
          return json;
        }
      );
    };
  }

  function responseAddEntity(response, entity) {
    return {
      type: "RESPONSE_ADD_ENTITY",
      response,
      entity
    };
  }
  
  export function addEntity(entity, data) {
    entity = entity.toLowerCase()
    return function (dispatch) {
      dispatch(requestUpdateEntity(entity));
      return api(`add/${entity}`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        dispatch(responseAddEntity(json, entity));
        return json;
      });
    };
  }

  export function addEntityWithFile(entity, data) {
    entity = entity.toLowerCase()
    return function (dispatch) {
      dispatch(requestUpdateEntity(entity));
      let apiMethod = data['files'] ? apiMultipleFormData : apiFormData
      return apiMethod(`add/${entity}`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        dispatch(responseAddEntity(json, entity));
        return json;
      });
    };
  }

  export function addRoWithFile(entity, data) {
    entity = entity.toLowerCase()
    return function (dispatch) {
      dispatch(requestUpdateEntity(entity));
      return apiFormData(`add/file_${entity}`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        dispatch(responseAddEntity(json, entity));
        return json;
      });
    };
  }

  function responseUpdateEntity(response, entity, id) {
    return {
      type: "RESPONSE_UPDATE_ENTITY",
      response,
      entity,
      id 
    };
  }
  
  export function updateEntity(entity, id, data) {
    return function (dispatch) {
      dispatch(requestUpdateEntity(entity.toLowerCase()));
      return api(`update/${entity.toLowerCase()}/${id}`, "PUT", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
          dispatch(responseUpdateEntity(json, entity, id));
          return json;
        }
      );
    };
  }

  export function fetchCouchBaseView(data) {
    return function (dispatch) {
      dispatch(requestFetchEntity("couchbase_view"));
      return api(`get/couchbase_view`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]){
        const type = `FETCH_COUCHBASE_VIEW`;
        dispatch(responseFetchCommon(type, json.data.entities));
        };
      });
    }
  }

  export function responseUpdateSelectedCreatives(response, entity, id) {
    return {
      type: "RESPONSE_UPDATE_SELECTED_CREATIVES",
      entity,
      id, 
      response 
    };
  }

  export function updateSelectedCreatives(data) {
    return function (dispatch) {
      dispatch(loading());
      return api(`update/selected-creatives`, "PUT", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
          dispatch(responseUpdateSelectedCreatives(json, data.entity, data.id));
          return json;
        }
      );
    };
  }

  export function fetchPublisherReportById(data, page_number) {
    return function (dispatch) {
      dispatch(requestFetchEntity("publisher_report"));
      return api(`get/publisher/report/${page_number}`, "POST", data).then((json) => {
        if (json.status === 401) return redirectToLogin();
        const type = `FETCH_PUBLISHER_REPORT`;
        let pageCount = json.data.page_count ? json.data.page_count : (json.data.page_count === 0 ? 1 : "")
        dispatch(responseFetchCommon(type, json.data.entities, pageCount));
        return json;
      });
    };
  }

  export function toggleSideBar(value) {
    return {
      type: "SIDEBAR_SHOW",
      value
    };
  }

  function requestFetchCreativeByCampId() {
    return {
      type: "REQUEST_FETCH_CREATIVE_BY_CAMP_ID",
      
    };
  }
  
  function responseFetchCreativeByCampId(id, response) {
    return {
      type: "RESPONSE_FETCH_CREATIVE_BY_CAMP_ID",
      id,
      response
    }
  }
  
  export function fetchCreativeByCampId(id) {
    return function (dispatch) {
      dispatch(requestFetchCreativeByCampId());
      return api(`get/campaign/creatives/data/${id}`, "GET").then((json) => {
        if (json.status === 401) return redirectToLogin();
        if (json["success"]) {
        dispatch(responseFetchCreativeByCampId(id, json.data));
        return json;
        };
      });
    }
  }