import { Map, fromJS } from "immutable";
import React from "react";
import defaultState from "../store/DefaultState";

export function Reducer(state = Map(), action) {
  switch (action.type) {
    case "LOADING":
      return state.set("loading", true);
    case "LOGGING_IN":
      return state.set("is_logging_in", true);
    case "LOGIN_FAILED":
      state = state.set("login_failed", true);
      return state.set("is_logging_in", false);
    case "LOGIN_SUCCEEDED":
      state = state.set("is_logged_in", true);
      state = state.set("logged_in_user", fromJS(action.user));
      return state.set("is_logging_in", false);
    case "LOGOUT_LOCAL":
      return defaultState;
    case "REQUEST_FETCH_ENTITY":
      return state.setIn([action.entity, "loading_"+action.entity], true);
    case "FETCH_CAMPAIGN":
      state = state.setIn(["campaign", "rows"], fromJS(action.payload));
      if (action.total_pages) state = state.setIn(["campaign", "page_count"], action.total_pages);
      return state.setIn(["campaign", "loading_campaign"], false);
    case "FETCH_CAMPAIGN_DATE_TIMESLOTS":
      state = state.setIn(["campaign_date_timeslots", "rows"], fromJS(action.payload));
      if (action.total_pages) state = state.setIn(["campaign", "page_count"], action.total_pages);
      return state.setIn(["campaign_date_timeslots", "loading_campaign_date_timeslots"], false);
    case "RESPONSE_UPDATE_STATUS":
      return state.setIn([action.entity, "action_response"], fromJS(action.response));
    case "FETCH_MASTER_ADTYPE":
      state = state.setIn(["master_adtype", "rows"], fromJS(action.payload));
      if (action.total_pages) state = state.setIn(["master_adtype", "page_count"], action.total_pages);
      return state.setIn(["master_adtype", "loading_master_adtype"], false);
    case "REQUEST_FETCH_CAMPAIGN_BY_ID":
      return state.set("is_fetching_single_campaign", true);
    case "RESPONSE_FETCH_CAMPAIGN_BY_ID":
      state = state.setIn(["campaign_single_row", action.id], fromJS(action.response))
        return state.set("is_fetching_single_campaign", false);
    case "REQUEST_FETCH_RO_BY_ID":
      return state.set("is_fetching_single_ro", true);
    case "RESPONSE_FETCH_RO_BY_ID":
      state = state.setIn(["ro_single_row", action.id], fromJS(action.response))
        return state.set("is_fetching_single_ro", false); 
    case "REQUEST_FETCH_SINGLE_ENTITY":
      return state.set("is_fetching_single_"+action.entity, true);
    case "RESPONSE_FETCH_SINGLE_ENTITY":
      state = state.setIn([action.entity+"_single_row", action.id], fromJS(action.data))
      state = state.set("is_fetching_single_"+action.entity, false); 
      return state
    case "FETCH_CATEGORY":
      state = state.setIn(["category", "rows"], fromJS(action.payload));
      return state.setIn(["category", "loading_category"], false);
    case "REQUEST_UPDATE_CAMPAIGN":
      return state.setIn(["campaign", "is_updating"], true);
    case "RESPONSE_UPDATE_CAMPAIGN":
      state = state.setIn(["campaign", "action_response"], fromJS(action.response));
      if (action.response.success === true && action.entity === 'campaign_irctc_targeting') {
        state = state.setIn(["campaign_irctc_targeting_single_row", action.id], fromJS())
        state = state.setIn(["campaign_irctc_targeting_single_row", action.id], fromJS([action.data]))

      }
      return state.setIn(["campaign", "is_updating"], false);
    case "FETCH_IRCTC_STATION_LOCATION":
      state = state.setIn(["irctc_station_location", "rows"], fromJS(action.payload));
      return state.setIn(["irctc_station_location", "loading_irctc_station_location"], false);
    case "REQUEST_UPDATE_ENTITY": 
    
      return state.setIn([action.entity, "is_updating"], true); 
      case "RESPONSE_UPDATE_ENTITY":
      state = state.setIn([action.entity, "action_response"], fromJS(action.response));      
      return state.setIn([action.entity, "is_updating"], false); 
    case "RESPONSE_DELETE_ENTITY":
      state = state.setIn([action.entity, "action_response"], fromJS(action.response));
      if (action.response.success === true) {
        state = deleteEntity(action.entity, state, action.id, action.campId);
      }
      return state.setIn([action.entity, "is_updating"], false);
    case "RESPONSE_ADD_ENTITY":
      state = state.setIn([action.entity, "action_response"], fromJS(action.response));
      if (action.response.success === true) {
        state = addEntity(action.entity, state, action.response.data);
      }
      return state.setIn([action.entity, "is_updating"], false);
    case "FETCH_MASTER_GEOIP_AUTOCOMPLETE":
      state = state.setIn(["master_geoip_autocomplete", "rows"], action.payload);
      return state.setIn(["master_geoip_autocomplete", "loading_master_geoip_autocomplete"], false);
    case "FETCH_ALL_CLIENTS":
      state = state.setIn(["all_clients", "rows"], action.payload);
      return state.setIn(["all_clients", "loading_all_clients"], false);
    case "FETCH_ROS":
      state = state.setIn(["ros", "rows"], action.payload);
      return state.setIn(["ros", "loading_ros"], false);
    case "FETCH_ROS_CLIENT":
      state = state.setIn(["ros_client", "rows"], action.payload);
      return state.setIn(["ros_client", "loading_ros_client"], false);
    case "FETCH_ROS_AGENCY":
      state = state.setIn(["ros_agency", "rows"], action.payload);
      return state.setIn(["ros_agency", "loading_ros_agency"], false);
    case "FETCH_ROS_FORMAT":
      state = state.setIn(["ros_format", "rows"], action.payload);
      return state.setIn(["ros_format", "loading_ros_format"], false);
    case "FETCH_REPORT":
      state = state.setIn(["report", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["report", "page_count"], action.total_pages);
      return state.setIn(["report", "loading_report"], false);
    case "FETCH_PUBLISHER_REPORT":
      state = state.setIn(["publisher_report", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["publisher_report", "page_count"], action.total_pages);
      return state.setIn(["publisher_report", "loading_publisher_report"], false);
    case "FETCH_PUBLISHER_DETAILED_REPORT":
      state = state.setIn(["publisher_detailed_report", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["publisher_detailed_report", "page_count"], action.total_pages);
      return state.setIn(["publisher_detailed_report", "loading_publisher_detailed_report"], false);  
    case "FETCH_SYSTEMCONFIGURATION":
        state = state.setIn(["systemconfiguration", "rows"], action.payload);
        return state.setIn(["systemconfiguration", "loading_systemconfiguration"], false);
    case "FETCH_NLP_TRACKING_URLS":
      state = state.setIn(["nlp_tracking_urls", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["nlp_tracking_urls", "page_count"], action.total_pages);
      return state.setIn(["nlp_tracking_urls", "loading_nlp_tracking_urls"], false);
    case "FETCH_DEMO_CAMPAIGN_AD":
      state = state.setIn(["demo_campaign_ad", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["demo_campaign_ad", "page_count"], action.total_pages);
      return state.setIn(["demo_campaign_ad", "loading_demo_campaign_ad"], false);
    case "FETCH_DEMO_AD_CLIENTS":
      state = state.setIn(["demo_ad_clients", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["demo_ad_clients", "page_count"], action.total_pages);
      return state.setIn(["demo_ad_clients", "loading_demo_ad_clients"], false);
    case "FETCH_ROS_FORMAT":
      state = state.setIn(["ros_format", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["ros_format", "page_count"], action.total_pages);
      return state.setIn(["ros_format", "loading_ros_format"], false);
    case "FETCH_REQ_BY_USERS":
      state = state.setIn(["req_by_users", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["req_by_users", "page_count"], action.total_pages);
      return state.setIn(["req_by_users", "loading_req_by_users"], false);        
    case "FETCH_GOOGLE_DFP_TRACKING":
      state = state.setIn(["google_dfp_tracking", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["google_dfp_tracking", "page_count"], action.total_pages);
      return state.setIn(["google_dfp_tracking", "loading_google_dfp_tracking"], false);
    case "FETCH_CDN_MEDIA":
      state = state.setIn(["cdn_media", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["cdn_media", "page_count"], action.total_pages);
      return state.setIn(["cdn_media", "loading_cdn_media"], false);
    case "FETCH_PROFIT_REPORT":
      state = state.setIn(["profit_report", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["profit_report", "page_count"], action.total_pages);
      return state.setIn(["profit_report", "loading_profit_report"], false);
    case "FETCH_COUCHBASE_VIEW":
      state = state.setIn(["couchbase_view", "rows"], fromJS(action.payload.rows));
      return state.setIn(["couchbase_view", "loading_couchbase_view"], false);
    case "RESPONSE_UPDATE_SELECTED_CREATIVES" :
      state = state.setIn([action.entity, "action_response"], fromJS(action.payload));
      return state.set("loading", false);
    case "SIDEBAR_SHOW":
      return state.set("sidebarShow", action.value); 
    case "FETCH_LOCAL_SYSTEMCONFIGURATION":
      state = state.setIn(["local_systemconfiguration", "rows"], action.payload);
      return state.setIn(["local_systemconfiguration", "loading_local_systemconfiguration"], false); 
    case "FETCH_AD_SERVING_AGENT":
      state = state.setIn(["ad_serving_agent", "rows"], action.payload);
      return state.setIn(["ad_serving_agent", "loading_ad_serving_agent"], false);
      case "FETCH_VAST_TAGS":
      state = state.setIn(["vast_tags", "rows"], action.payload);
      if (action.total_pages) state = state.setIn(["vast_tags", "page_count"], action.total_pages);
      return state.setIn(["vast_tags", "loading_vast_tags"], false);
    case "REQUEST_FETCH_CREATIVE_BY_CAMP_ID":
      return state.set("creatives_is_fetching", true);
    case "RESPONSE_FETCH_CREATIVE_BY_CAMP_ID":
      state = state.setIn(["creatives", action.id], fromJS(action.response))
        return state.set("creatives_is_fetching", false);              
    default:
      return state;
  }
}

  function deleteEntity(entity, state, id, campId){
    if (entity === 'campaign_irctc_targeting') {
      return state.setIn(["campaign_irctc_targeting_single_row", id], [])
    }
    if (['campaign_ad_trackers', 'addata'].includes(entity)) {
      let rows = state.getIn([entity+"_single_row", campId])
      rows = rows.filter((i)=> i.id !== id)
      return state.setIn([entity, campId], fromJS(rows))
    }
    return state
  }

  function addEntity(entity, state, data) {
    if (entity === 'campaign_ad_trackers') {
      let prev = state.getIn(["campaign_ad_trackers_single_row", data.campaign_id]).toJS();
      prev.push(data)
      return state.setIn(["campaign_ad_trackers_single_row", data.campaign_id], fromJS(prev));
    }
    if (entity === 'addata') {
      let prev = state.getIn(["addata_single_row", data.campaign_id]).toJS();
      prev.push(data)
      return state.setIn(["addata_single_row", data.campaign_id], fromJS(prev));
    }
    return state
  }

export const Context = React.createContext();

