import { createStore, applyMiddleware } from "redux";
import { Reducer } from "../reducers/MainReducer";
import defaultState from "./DefaultState";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

  const loggerMiddleware = createLogger();

export default function configureStore() {
  const store = createStore(
    Reducer,
    defaultState,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  );

  return store;
}
