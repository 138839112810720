import { fromJS } from "immutable";

const defaultState = fromJS({
  sidebarShow: false,
  loading: false,
  is_logging_in: false,
  login_failed: false,
  logged_in_user: {},
  is_logged_in: false,
  is_fetching_single_campaign: false,
  is_fetching_single_ro: false,
  is_fetching_single_campaign_irctc_targeting: false,
  is_fetching_single_campaign_ad_trackers: false,
  is_fetching_single_campaign_geo_targeting: false,
  is_fetching_single_addata: false,
  is_fetching_single_selected_publishers: false,

  campaign: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_campaign: false,
    page_count: ''
  },
  campaign_date_timeslots: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_campaign_date_timeslots: false,
    page_count: ''
  },
  master_adtype: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_master_adtype: false,
    page_count: ''
  },
  campaign_single_row: {},
  ro_single_row: {},
  category_to_ad_sigle_row: {},
  campaign_irctc_targeting_single_row: {},
  campaign_irctc_targeting: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_campaign_irctc_targeting: false,
    page_count: ''
  },
  category: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_category: false,
    page_count: ''
  },
  irctc_station_location: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_irctc_station_location: false,
    page_count: ''
  },
  campaign_ad_trackers_single_row: {},
  campaign_ad_trackers: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  master_geoip_autocomplete: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_master_geoip_autocomplete: false,
    page_count: ''
  },
  campaign_geo_targeting_single_row: {},
  campaign_geo_targeting: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  addata_single_row: {},
  addata: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  campaign_date_timeslots_single_row: {},
  campaign_date_timeslots: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  publisher_to_ad_single_row: {},
  publisher_to_ad: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  all_clients: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_all_clients: false,
    page_count: ''
  },
  selected_publishers_single_row: {},
  selected_publishers: {
    action_response: {},
    is_updating: false,
    loading_selected_publishers: false,
  },
  ros: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_ros: false,
  },
  ros_client: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_ros_client: false,
  },
  ros_agency: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_ros_agency: false,
  },
  ros_format: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_ros_format: false,
  },
  report: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_report: false,
    page_count: '',
  },
  publisher_report: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_publisher_report: false,
    page_count: '',
  },
  profit_report: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_profit_report: false,
    page_count: '',
  },
  systemconfiguration: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_systemconfiguration: false,
  },
  nlp_tracking_urls: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_nlp_tracking_urls: false,
    page_count: '',
  },
  demo_campaign_ad: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_demo_campaign_ad: false,
    page_count: '',
  },
  demo_addata_single_row: {},
  demo_addata: {
    action_response: {},
    is_updating: false,
    loading_category: false,
  },
  demo_ad_clients: {
    rows: [],
    loading_demo_ad_clients: false,
  },
  ros_format: {
    rows: [],
    loading_ros_format: false,
  },
  req_by_users: {
    rows: [],
    loading_req_by_users: false,
  },
  google_dfp_tracking: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_google_dfp_tracking: false,
    page_count: '',
  },
  cdn_media: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_cdn_media: false,
    page_count: '',
  },
  publisher_detailed_report: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_publisher_detailed_report: false,
    page_count: '',
  },
  couchbase_view: {
    rows: [],
    action_response: {},
    loading_couchbase_view: false,
  },
  local_systemconfiguration: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_local_systemconfiguration: false,
  },
  ad_serving_agent: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_ad_serving_agent: false,
  },
  vast_tags: {
    rows: [],
    action_response: {},
    is_updating: false,
    loading_vast_tags: false,
    page_count: '',
  },
  creatives: {
    is_fetching: false
  }
});

export default defaultState;
